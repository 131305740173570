export enum TeamID {
  Arsenal,
  AstonVilla,
  Brentford,
  Brighton,
  Burnley,
  Chelsea,
  CrystalPalace,
  Everton,
  Leeds,
  Leicester,
  Liverpool,
  ManchesterCity,
  ManchesterUnited,
  Newcastle,
  Norwich,
  Southampton,
  Tottenham,
  Watford,
  WestHam,
  Wolverhampton,
}

export interface Team {
  id: TeamID;
  name: string;
  colour: string;
}

export const teams: {[id in TeamID]: Team} = {
  [TeamID.Arsenal]: {id: TeamID.Arsenal, name: 'Arsenal', colour: '#EF0107'},
  [TeamID.AstonVilla]: {id: TeamID.AstonVilla, name: 'Aston Villa', colour: '#95BFE5'},
  [TeamID.Brentford]: {id: TeamID.Brentford, name: 'Brentford', colour: '#FBB800'},
  [TeamID.Brighton]: {id: TeamID.Brighton, name: 'Brighton', colour: '#0057B8'},
  [TeamID.Burnley]: {id: TeamID.Burnley, name: 'Burnley', colour: '#6C1D45'},
  [TeamID.Chelsea]: {id: TeamID.Chelsea, name: 'Chelsea', colour: '#034694'},
  [TeamID.CrystalPalace]: {id: TeamID.CrystalPalace, name: 'Crystal Palace', colour: '#1B458F'},
  [TeamID.Everton]: {id: TeamID.Everton, name: 'Everton', colour: '#003399'},
  [TeamID.Leeds]: {id: TeamID.Leeds, name: 'Leeds', colour: '#FFCD00'},
  [TeamID.Leicester]: {id: TeamID.Leicester, name: 'Leicester', colour: '#003090'},
  [TeamID.Liverpool]: {id: TeamID.Liverpool, name: 'Liverpool', colour: '#C8102E'},
  [TeamID.ManchesterCity]: {id: TeamID.ManchesterCity, name: 'Manchester City', colour: '#6CABDD'},
  [TeamID.ManchesterUnited]: {id: TeamID.ManchesterUnited, name: 'Manchester United', colour: '#DA291C'},
  [TeamID.Newcastle]: {id: TeamID.Newcastle, name: 'Newcastle', colour: '#241F20'},
  [TeamID.Norwich]: {id: TeamID.Norwich, name: 'Norwich', colour: '#FFF200'},
  [TeamID.Southampton]: {id: TeamID.Southampton, name: 'Southampton', colour: '#D71920'},
  [TeamID.Tottenham]: {id: TeamID.Tottenham, name: 'Tottenham', colour: '#132257'},
  [TeamID.Watford]: {id: TeamID.Watford, name: 'Watford', colour: '#FBEE23'},
  [TeamID.WestHam]: {id: TeamID.WestHam, name: 'West Ham', colour: '#7A263A'},
  [TeamID.Wolverhampton]: {id: TeamID.Wolverhampton, name: 'Wolverhampton', colour: '#FDB913'},
};

export interface Prediction {
  name: string;
  color: string;
  table: TeamID[];
  fa: TeamID;
  liga: TeamID;
  topscorer: string;
}

export interface PredictionResult {
  name: string;
  score: number;
  accumulated: number;
}

// Colours:  #caf2b3

export const predictions: Prediction[] = [
  {
    name: 'Morten',
    color: '#e31b23',
    table: [
      TeamID.ManchesterCity,
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.ManchesterUnited,
      TeamID.Leicester,
      TeamID.Everton,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.Leeds,
      TeamID.WestHam,
      TeamID.AstonVilla,
      TeamID.Newcastle,
      TeamID.Wolverhampton,
      TeamID.Brighton,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.Southampton,
      TeamID.Burnley,
      TeamID.Watford,
      TeamID.Norwich,
    ],
    fa: TeamID.Arsenal,
    liga: TeamID.ManchesterCity,
    topscorer: 'Romalu Lukaku'
  },
  {
    name: 'Lasse',
    color: '#819eed',
    table: [
      TeamID.ManchesterCity,
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.ManchesterUnited,
      TeamID.Tottenham,
      TeamID.Leicester,
      TeamID.Arsenal,
      TeamID.Everton,
      TeamID.Leeds,
      TeamID.WestHam,
      TeamID.AstonVilla,
      TeamID.Wolverhampton,
      TeamID.Newcastle,
      TeamID.Brighton,
      TeamID.Southampton,
      TeamID.CrystalPalace,
      TeamID.Burnley,
      TeamID.Brentford,
      TeamID.Norwich,
      TeamID.Watford
    ],
    fa: TeamID.Leicester,
    liga: TeamID.Leicester,
    topscorer: 'Romalu Lukaku'
  },
  {
    name: 'Maul',
    color: '#bdb029',
    table: [
      TeamID.Brentford,
      TeamID.Leicester,
      TeamID.ManchesterCity,
      TeamID.Tottenham,
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.ManchesterUnited,
      TeamID.Arsenal,
      TeamID.Everton,
      TeamID.AstonVilla,
      TeamID.Southampton,
      TeamID.Newcastle,
      TeamID.Wolverhampton,
      TeamID.Burnley,
      TeamID.WestHam,
      TeamID.CrystalPalace,
      TeamID.Norwich,
      TeamID.Leeds,
      TeamID.Brighton,
      TeamID.Watford
    ],
    fa: TeamID.ManchesterCity,
    liga: TeamID.Liverpool,
    topscorer: 'Jamie Vardy'
  },
  {
    name: 'Steen',
    color: '#d62074',
    table: [
      TeamID.ManchesterCity,
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.ManchesterUnited,
      TeamID.Leicester,
      TeamID.Tottenham,
      TeamID.Arsenal,
      TeamID.WestHam,
      TeamID.Everton,
      TeamID.AstonVilla,
      TeamID.Southampton,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.Newcastle,
      TeamID.Brighton,
      TeamID.Watford,
      TeamID.Norwich,
      TeamID.Wolverhampton,
      TeamID.Burnley,
      TeamID.Leeds,
    ],
    fa: TeamID.Chelsea,
    liga: TeamID.Arsenal,
    topscorer: 'Bruno Fernandes'
  },
  {
    name: 'Thomas',
    color: '#f87e6c',
    table: [
      TeamID.ManchesterUnited,
      TeamID.Liverpool,
      TeamID.Leicester,
      TeamID.ManchesterCity,
      TeamID.WestHam,
      TeamID.Chelsea,
      TeamID.Brentford,
      TeamID.Tottenham,
      TeamID.AstonVilla,
      TeamID.Everton,
      TeamID.Wolverhampton,
      TeamID.Arsenal,
      TeamID.Newcastle,
      TeamID.Watford,
      TeamID.Brighton,
      TeamID.Leeds,
      TeamID.Southampton,
      TeamID.CrystalPalace,
      TeamID.Norwich,
      TeamID.Burnley,
    ],
    fa: TeamID.Leicester,
    liga: TeamID.Brentford,
    topscorer: 'Bruno Fernandes'
  },
  {
    name: 'Klavs',
    color: '#c2953f',
    table: [
      TeamID.Everton,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.ManchesterUnited,
      TeamID.ManchesterCity,
      TeamID.Tottenham,
      TeamID.Leicester,
      TeamID.Arsenal,
      TeamID.WestHam,
      TeamID.Leeds,
      TeamID.AstonVilla,
      TeamID.Brighton,
      TeamID.Southampton,
      TeamID.Wolverhampton,
      TeamID.Brentford,
      TeamID.Watford,
      TeamID.Newcastle,
      TeamID.CrystalPalace,
      TeamID.Burnley,
      TeamID.Norwich,
    ],
    fa: TeamID.Everton,
    liga: TeamID.Everton,
    topscorer: 'Richarlison'
  },
  {
    name: 'Korch',
    color: '#27059e',
    table: [
      TeamID.Chelsea,
      TeamID.ManchesterCity,
      TeamID.ManchesterUnited,
      TeamID.Liverpool,
      TeamID.Tottenham,
      TeamID.Leicester,
      TeamID.Everton,
      TeamID.Arsenal,
      TeamID.WestHam,
      TeamID.AstonVilla,
      TeamID.Wolverhampton,
      TeamID.Watford,
      TeamID.Southampton,
      TeamID.Newcastle,
      TeamID.Leeds,
      TeamID.Burnley,
      TeamID.Brentford,
      TeamID.Brighton,
      TeamID.CrystalPalace,
      TeamID.Norwich,
    ],
    fa: TeamID.ManchesterUnited,
    liga: TeamID.Chelsea,
    topscorer: 'Romalu Lukaku'
  },
  {
    name: 'Sondrup',
    color: '#2f963b',
    table: [
      TeamID.Liverpool,
      TeamID.ManchesterCity,
      TeamID.ManchesterUnited,
      TeamID.Chelsea,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.Leicester,
      TeamID.AstonVilla,
      TeamID.WestHam,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Brighton,
      TeamID.Newcastle,
      TeamID.Wolverhampton,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.Southampton,
      TeamID.Burnley,
      TeamID.Norwich,
      TeamID.Watford,
    ],
    fa: TeamID.Liverpool,
    liga: TeamID.Liverpool,
    topscorer: 'Mohamed Salah'
  },
  {
    name: 'Larsen',
    color: '#85306b',
    table: [
      TeamID.ManchesterUnited,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.ManchesterCity,
      TeamID.Leicester,
      TeamID.Tottenham,
      TeamID.WestHam,
      TeamID.Arsenal,
      TeamID.Everton,
      TeamID.Brentford,
      TeamID.AstonVilla,
      TeamID.Wolverhampton,
      TeamID.Newcastle,
      TeamID.Southampton,
      TeamID.Leeds,
      TeamID.Watford,
      TeamID.Brighton,
      TeamID.CrystalPalace,
      TeamID.Burnley,
      TeamID.Norwich,
    ],
    fa: TeamID.ManchesterUnited,
    liga: TeamID.ManchesterUnited,
    topscorer: 'Romalu Lukaku'
  },
  {
    name: 'Søren',
    color: '#614bd8',
    table: [
      TeamID.Chelsea,
      TeamID.ManchesterUnited,
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Tottenham,
      TeamID.Leicester,
      TeamID.Everton,
      TeamID.WestHam,
      TeamID.Arsenal,
      TeamID.Brentford,
      TeamID.Wolverhampton,
      TeamID.CrystalPalace,
      TeamID.Southampton,
      TeamID.AstonVilla,
      TeamID.Watford,
      TeamID.Newcastle,
      TeamID.Brighton,
      TeamID.Burnley,
      TeamID.Leeds,
      TeamID.Norwich,
    ],
    fa: TeamID.Tottenham,
    liga: TeamID.Everton,
    topscorer: 'Romalu Lukaku'
  },
  {
    name: 'Rasmus',
    color: '#21568d',
    table: [
      TeamID.ManchesterUnited,
      TeamID.ManchesterCity,
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.Tottenham,
      TeamID.Leicester,
      TeamID.Arsenal,
      TeamID.WestHam,
      TeamID.Everton,
      TeamID.Brighton,
      TeamID.Leeds,
      TeamID.AstonVilla,
      TeamID.Brentford,
      TeamID.Southampton,
      TeamID.Wolverhampton,
      TeamID.Burnley,
      TeamID.CrystalPalace,
      TeamID.Watford,
      TeamID.Newcastle,
      TeamID.Norwich,
    ],
    fa: TeamID.ManchesterUnited,
    liga: TeamID.ManchesterCity,
    topscorer: 'Bruno Fernandes'
  },
  {
    name: 'Henrik',
    color: '#01f7cb',
    table: [
      TeamID.Leicester,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.ManchesterUnited,
      TeamID.ManchesterCity,
      TeamID.WestHam,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Wolverhampton,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.Norwich,
      TeamID.Brighton,
      TeamID.Brentford,
      TeamID.CrystalPalace,
      TeamID.AstonVilla,
      TeamID.Newcastle,
      TeamID.Southampton,
      TeamID.Watford,
      TeamID.Burnley,
    ],
    fa: TeamID.Arsenal,
    liga: TeamID.ManchesterUnited,
    topscorer: 'Jamie Vardy'
  },
];

export interface Table {
  round: string,
  updated: Date,
  table: TeamID[],
  fa: TeamID,
  liga: TeamID,
  topscorer: string[],
}

export const tables: Table[] = [
  {
    round: 'Runde 0',
    updated: new Date(2021, 7, 12, 12, 0, 0), 
    fa: null,
    liga: null,
    topscorer: [],
    table: [
      TeamID.Arsenal,
      TeamID.AstonVilla,
      TeamID.Brentford,
      TeamID.Brighton,
      TeamID.Burnley,
      TeamID.Chelsea,
      TeamID.CrystalPalace,
      TeamID.Everton,
      TeamID.Leeds,
      TeamID.Leicester,
      TeamID.Liverpool,
      TeamID.ManchesterCity,
      TeamID.ManchesterUnited,
      TeamID.Newcastle,
      TeamID.Norwich,
      TeamID.Southampton,
      TeamID.Tottenham,
      TeamID.Watford,
      TeamID.WestHam,
      TeamID.Wolverhampton,
    ]
  },
  {
    round: 'Runde 1',
    updated: new Date(2021, 7, 15, 20, 20, 0), 
    fa: null,
    liga: null,
    topscorer: ['Bruno Fernandes'],
    table: [
      TeamID.ManchesterUnited,
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.WestHam,
      TeamID.Everton,
      TeamID.Brentford,
      TeamID.Watford,
      TeamID.Brighton,
      TeamID.Leicester,
      TeamID.Tottenham,
      TeamID.AstonVilla,
      TeamID.Burnley,
      TeamID.ManchesterCity,
      TeamID.Wolverhampton,
      TeamID.Newcastle,
      TeamID.Southampton,
      TeamID.Arsenal,
      TeamID.CrystalPalace,
      TeamID.Norwich,
      TeamID.Leeds,
    ]
  },
  {
    round: 'Runde 2',
    updated: new Date(2021, 7, 25, 15, 50, 0), 
    fa: null,
    liga: null,
    topscorer: ['Bruno Fernandes'],
    table: [
      TeamID.WestHam,
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.Brighton,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.Everton,
      TeamID.Brentford,
      TeamID.ManchesterCity,
      TeamID.AstonVilla,
      TeamID.Watford,
      TeamID.Leicester,
      TeamID.Southampton,
      TeamID.CrystalPalace,
      TeamID.Leeds,
      TeamID.Wolverhampton,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Arsenal,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 3',
    updated: new Date(2021, 7, 29, 20, 45, 0), 
    fa: null,
    liga: null,
    topscorer: ['Michail Antonio'],
    table: [
      TeamID.Tottenham,
      TeamID.WestHam,
      TeamID.ManchesterUnited,
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.Everton,
      TeamID.ManchesterCity,
      TeamID.Brighton,
      TeamID.Leicester,
      TeamID.Brentford,
      TeamID.AstonVilla,
      TeamID.Watford,
      TeamID.Southampton,
      TeamID.CrystalPalace,
      TeamID.Leeds,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Wolverhampton,
      TeamID.Norwich,
      TeamID.Arsenal,
    ]
  },
  {
    round: 'Runde 4',
    updated: new Date(2021, 8, 12, 20, 55, 0), 
    fa: null,
    liga: null,
    topscorer: ['Bruno Fernandes'],
    table: [
      TeamID.ManchesterUnited,
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.ManchesterCity,
      TeamID.Brighton,
      TeamID.Tottenham,
      TeamID.WestHam,
      TeamID.Everton,
      TeamID.Leicester,
      TeamID.Brentford,
      TeamID.CrystalPalace,
      TeamID.AstonVilla,
      TeamID.Wolverhampton,
      TeamID.Southampton,
      TeamID.Watford,
      TeamID.Arsenal,
      TeamID.Leeds,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 5',
    updated: new Date(2021, 8, 22, 17, 0, 0), 
    fa: null,
    liga: null,
    topscorer: ['Bruno Fernandes', 'Mohamed Salah', 'Michail Antonio'],
    table: [
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.ManchesterUnited,
      TeamID.Brighton,
      TeamID.ManchesterCity,
      TeamID.Everton,
      TeamID.Tottenham,
      TeamID.WestHam,
      TeamID.Brentford,
      TeamID.AstonVilla,
      TeamID.Watford,
      TeamID.Leicester,
      TeamID.Arsenal,
      TeamID.CrystalPalace,
      TeamID.Southampton,
      TeamID.Wolverhampton,
      TeamID.Leeds,
      TeamID.Newcastle,
      TeamID.Burnley,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 6',
    updated: new Date(2021, 8, 28, 10, 0, 0), 
    fa: null,
    liga: null,
    topscorer: ['Jamie Vardy', 'Mohamed Salah', 'Michail Antonio'],
    table: [
      TeamID.Liverpool,
      TeamID.ManchesterCity,
      TeamID.Chelsea,
      TeamID.ManchesterUnited,
      TeamID.Everton,
      TeamID.Brighton,
      TeamID.WestHam,
      TeamID.AstonVilla,
      TeamID.Brentford,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.Watford,
      TeamID.Leicester,
      TeamID.Wolverhampton,
      TeamID.CrystalPalace,
      TeamID.Southampton,
      TeamID.Newcastle,
      TeamID.Leeds,
      TeamID.Burnley,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 7',
    updated: new Date(2021, 9, 4, 10, 10, 0), 
    fa: null,
    liga: null,
    topscorer: ['Jamie Vardy', 'Mohamed Salah'],
    table: [
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.ManchesterCity,
      TeamID.ManchesterUnited,
      TeamID.Everton,
      TeamID.Brighton,
      TeamID.Brentford,
      TeamID.Tottenham,
      TeamID.WestHam,
      TeamID.AstonVilla,
      TeamID.Arsenal,
      TeamID.Wolverhampton,
      TeamID.Leicester,
      TeamID.CrystalPalace,
      TeamID.Watford,
      TeamID.Leeds,
      TeamID.Southampton,      
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 8',
    updated: new Date(2021, 9, 19, 22, 0, 0), 
    fa: null,
    liga: null,
    topscorer: ['Jamie Vardy', 'Mohamed Salah'],
    table: [
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.ManchesterCity,
      TeamID.Brighton,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.WestHam,
      TeamID.Everton,
      TeamID.Brentford,
      TeamID.Wolverhampton,
      TeamID.Leicester,
      TeamID.Arsenal,
      TeamID.AstonVilla,
      TeamID.CrystalPalace,
      TeamID.Southampton,      
      TeamID.Watford,
      TeamID.Leeds,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 9',
    updated: new Date(2021, 9, 25, 17, 10, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.ManchesterCity,
      TeamID.WestHam,
      TeamID.Brighton,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.Everton,
      TeamID.Leicester,
      TeamID.Arsenal,
      TeamID.Wolverhampton,
      TeamID.Brentford,
      TeamID.AstonVilla,
      TeamID.Watford,
      TeamID.CrystalPalace,
      TeamID.Southampton,      
      TeamID.Leeds,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 10',
    updated: new Date(2021, 10, 2, 9, 40, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.ManchesterCity,
      TeamID.WestHam,
      TeamID.ManchesterUnited,
      TeamID.Arsenal,
      TeamID.Wolverhampton,
      TeamID.Brighton,
      TeamID.Tottenham,
      TeamID.Everton,
      TeamID.Leicester,
      TeamID.Brentford,
      TeamID.CrystalPalace,
      TeamID.Southampton,      
      TeamID.AstonVilla,
      TeamID.Watford,
      TeamID.Leeds,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 11',
    updated: new Date(2021, 10, 9, 21, 10, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.Chelsea,
      TeamID.ManchesterCity,
      TeamID.WestHam,
      TeamID.Liverpool,
      TeamID.Arsenal,
      TeamID.ManchesterUnited,
      TeamID.Brighton,
      TeamID.Wolverhampton,
      TeamID.Tottenham,
      TeamID.CrystalPalace,
      TeamID.Everton,
      TeamID.Leicester,
      TeamID.Southampton,      
      TeamID.Brentford,
      TeamID.Leeds,
      TeamID.AstonVilla,
      TeamID.Watford,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 12',
    updated: new Date(2021, 10, 22, 16, 0, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.Chelsea,
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.WestHam,
      TeamID.Arsenal,
      TeamID.Wolverhampton,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.Brighton,
      TeamID.CrystalPalace,
      TeamID.Everton,
      TeamID.Leicester,
      TeamID.Southampton,      
      TeamID.Brentford,
      TeamID.AstonVilla,
      TeamID.Watford,
      TeamID.Leeds,
      TeamID.Burnley,
      TeamID.Norwich,
      TeamID.Newcastle,
    ]
  },
  {
    round: 'Runde 13',
    updated: new Date(2021, 10, 30, 17, 35, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.Chelsea,
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.WestHam,
      TeamID.Arsenal,
      TeamID.Wolverhampton,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.Brighton,
      TeamID.Leicester,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.AstonVilla,
      TeamID.Everton,
      TeamID.Southampton,
      TeamID.Watford,
      TeamID.Leeds,
      TeamID.Burnley,
      TeamID.Norwich,
      TeamID.Newcastle,
    ]
  },
  {
    round: 'Runde 14',
    updated: new Date(2021, 11, 3, 16, 7, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.Chelsea,
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.WestHam,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.Wolverhampton,
      TeamID.Brighton,
      TeamID.Leicester,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.AstonVilla,
      TeamID.Everton,
      TeamID.Leeds,
      TeamID.Southampton,
      TeamID.Watford,
      TeamID.Burnley,
      TeamID.Norwich,
      TeamID.Newcastle,
    ]
  },
  {
    round: 'Runde 15',
    updated: new Date(2021, 11, 10, 21, 23, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.WestHam,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.Arsenal,
      TeamID.Wolverhampton,
      TeamID.Brighton,
      TeamID.AstonVilla,
      TeamID.Leicester,
      TeamID.Everton,
      TeamID.Brentford,
      TeamID.CrystalPalace,
      TeamID.Leeds,
      TeamID.Southampton,
      TeamID.Watford,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 16',
    updated: new Date(2021, 11, 14, 8, 0, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.WestHam,
      TeamID.ManchesterUnited,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.Leicester,
      TeamID.Wolverhampton,
      TeamID.Brentford,
      TeamID.Brighton,
      TeamID.CrystalPalace,
      TeamID.AstonVilla,
      TeamID.Everton,
      TeamID.Leeds,
      TeamID.Southampton,
      TeamID.Watford,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 17',
    updated: new Date(2021, 11, 16, 23, 10, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Arsenal,
      TeamID.WestHam,
      TeamID.ManchesterUnited,
      TeamID.Tottenham,
      TeamID.Wolverhampton,
      TeamID.Leicester,
      TeamID.AstonVilla,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.Brighton,
      TeamID.Everton,
      TeamID.Southampton,
      TeamID.Leeds,
      TeamID.Watford,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 18',
    updated: new Date(2021, 11, 19, 19, 50, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Arsenal,
      TeamID.WestHam,
      TeamID.ManchesterUnited,
      TeamID.Tottenham,
      TeamID.Wolverhampton,
      TeamID.Leicester,
      TeamID.AstonVilla,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.Brighton,
      TeamID.Everton,
      TeamID.Southampton,
      TeamID.Leeds,
      TeamID.Watford,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 19',
    updated: new Date(2021, 11, 27, 16, 30, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.WestHam,
      TeamID.ManchesterUnited,
      TeamID.Wolverhampton,
      TeamID.Brighton,
      TeamID.Leicester,
      TeamID.AstonVilla,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.Southampton,
      TeamID.Everton,
      TeamID.Leeds,
      TeamID.Watford,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 21',
    updated: new Date(2022, 0, 3, 21, 30, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Chelsea,
      TeamID.Liverpool,
      TeamID.Arsenal,
      TeamID.WestHam,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.Wolverhampton,
      TeamID.Brighton,
      TeamID.Leicester,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.AstonVilla,
      TeamID.Southampton,
      TeamID.Everton,
      TeamID.Leeds,
      TeamID.Watford,
      TeamID.Burnley,
      TeamID.Newcastle,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 22',
    updated: new Date(2022, 0, 16, 17, 16, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.WestHam,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.Wolverhampton,
      TeamID.Brighton,
      TeamID.Leicester,
      TeamID.CrystalPalace,
      TeamID.Southampton,
      TeamID.AstonVilla,
      TeamID.Brentford,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Watford,
      TeamID.Norwich,
      TeamID.Newcastle,
      TeamID.Burnley,
    ]
  },
  {
    round: 'Runde 23',
    updated: new Date(2022, 0, 24, 11, 55, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.ManchesterUnited,
      TeamID.WestHam,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.Wolverhampton,
      TeamID.Brighton,
      TeamID.Leicester,
      TeamID.AstonVilla,
      TeamID.Southampton,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Norwich,
      TeamID.Newcastle,
      TeamID.Watford,
      TeamID.Burnley,
    ]
  },
  {
    round: 'Runde 24',
    updated: new Date(2022, 1, 11, 7, 50, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.WestHam,
      TeamID.Arsenal,
      TeamID.ManchesterUnited,
      TeamID.Tottenham,
      TeamID.Wolverhampton,
      TeamID.Brighton,
      TeamID.Southampton,
      TeamID.AstonVilla,
      TeamID.Leicester,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Newcastle,
      TeamID.Norwich,
      TeamID.Watford,
      TeamID.Burnley,
    ]
  },
  {
    round: 'Runde 25',
    updated: new Date(2022, 1, 14, 14, 10, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.WestHam,
      TeamID.ManchesterUnited,
      TeamID.Arsenal,
      TeamID.Wolverhampton,
      TeamID.Tottenham,
      TeamID.Brighton,
      TeamID.Southampton,
      TeamID.Leicester,
      TeamID.AstonVilla,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Newcastle,
      TeamID.Norwich,
      TeamID.Watford,
      TeamID.Burnley,
    ]
  },
  {
    round: 'Runde 26',
    updated: new Date(2022, 1, 21, 15, 55, 0), 
    fa: null,
    liga: null,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.ManchesterUnited,
      TeamID.WestHam,
      TeamID.Arsenal,
      TeamID.Wolverhampton,
      TeamID.Tottenham,
      TeamID.Brighton,
      TeamID.Southampton,
      TeamID.Leicester,
      TeamID.AstonVilla,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Newcastle,
      TeamID.Watford,
      TeamID.Burnley,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 27',
    updated: new Date(2022, 1, 28, 10, 30, 0), 
    fa: null,
    liga: TeamID.Liverpool,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.ManchesterUnited,
      TeamID.WestHam,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.Wolverhampton,
      TeamID.Southampton,
      TeamID.Brighton,
      TeamID.CrystalPalace,
      TeamID.AstonVilla,
      TeamID.Leicester,
      TeamID.Newcastle,
      TeamID.Brentford,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Burnley,
      TeamID.Watford,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 28',
    updated: new Date(2022, 2, 8, 17, 20, 0), 
    fa: null,
    liga: TeamID.Liverpool,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Arsenal,
      TeamID.ManchesterUnited,
      TeamID.WestHam,
      TeamID.Tottenham,
      TeamID.Wolverhampton,
      TeamID.Southampton,
      TeamID.CrystalPalace,
      TeamID.AstonVilla,
      TeamID.Leicester,
      TeamID.Brighton,
      TeamID.Newcastle,
      TeamID.Brentford,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Burnley,
      TeamID.Watford,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 29',
    updated: new Date(2022, 2, 15, 17, 15, 0), 
    fa: null,
    liga: TeamID.Liverpool,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Arsenal,
      TeamID.ManchesterUnited,
      TeamID.WestHam,
      TeamID.Wolverhampton,
      TeamID.Tottenham,
      TeamID.AstonVilla,
      TeamID.Southampton,
      TeamID.CrystalPalace,
      TeamID.Leicester,
      TeamID.Brighton,
      TeamID.Newcastle,
      TeamID.Brentford,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Watford,
      TeamID.Burnley,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 31',
    updated: new Date(2022, 3, 5, 11, 50, 0), 
    fa: null,
    liga: TeamID.Liverpool,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Tottenham,
      TeamID.Arsenal,
      TeamID.WestHam,
      TeamID.ManchesterUnited,
      TeamID.Wolverhampton,
      TeamID.CrystalPalace,
      TeamID.Leicester,
      TeamID.AstonVilla,
      TeamID.Southampton,
      TeamID.Brighton,
      TeamID.Brentford,
      TeamID.Newcastle,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Watford,
      TeamID.Burnley,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 32',
    updated: new Date(2022, 3, 10, 21, 40, 0), 
    fa: null,
    liga: TeamID.Liverpool,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Tottenham,
      TeamID.Arsenal,
      TeamID.WestHam,
      TeamID.ManchesterUnited,
      TeamID.Wolverhampton,
      TeamID.Leicester,
      TeamID.CrystalPalace,
      TeamID.Brighton,
      TeamID.AstonVilla,
      TeamID.Brentford,
      TeamID.Southampton,
      TeamID.Newcastle,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Burnley,
      TeamID.Watford,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 33',
    updated: new Date(2022, 3, 18, 21, 40, 0), 
    fa: null,
    liga: TeamID.Liverpool,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.Arsenal,
      TeamID.WestHam,
      TeamID.Wolverhampton,
      TeamID.Leicester,
      TeamID.Brighton,
      TeamID.Brentford,
      TeamID.Southampton,
      TeamID.CrystalPalace,
      TeamID.Newcastle,
      TeamID.AstonVilla,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Burnley,
      TeamID.Watford,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 34',
    updated: new Date(2022, 3, 26, 9, 50, 0), 
    fa: null,
    liga: TeamID.Liverpool,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.WestHam,
      TeamID.Wolverhampton,
      TeamID.Newcastle,
      TeamID.Leicester,
      TeamID.Brighton,
      TeamID.Brentford,
      TeamID.Southampton,
      TeamID.CrystalPalace,
      TeamID.AstonVilla,
      TeamID.Leeds,
      TeamID.Burnley,
      TeamID.Everton,
      TeamID.Watford,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 35',
    updated: new Date(2022, 4, 2, 8, 50, 0), 
    fa: null,
    liga: TeamID.Liverpool,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.WestHam,
      TeamID.Wolverhampton,
      TeamID.Brighton,
      TeamID.Newcastle,
      TeamID.Leicester,
      TeamID.CrystalPalace,
      TeamID.AstonVilla,
      TeamID.Brentford,
      TeamID.Southampton,
      TeamID.Burnley,
      TeamID.Leeds,
      TeamID.Everton,
      TeamID.Watford,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 36',
    updated: new Date(2022, 4, 9, 10, 12, 0), 
    fa: null,
    liga: TeamID.Liverpool,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Arsenal,
      TeamID.Tottenham,
      TeamID.ManchesterUnited,
      TeamID.WestHam,
      TeamID.Wolverhampton,
      TeamID.Brighton,
      TeamID.CrystalPalace,
      TeamID.AstonVilla,
      TeamID.Brentford,
      TeamID.Newcastle,
      TeamID.Leicester,
      TeamID.Southampton,
      TeamID.Everton,
      TeamID.Burnley,
      TeamID.Leeds,
      TeamID.Watford,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 37',
    updated: new Date(2022, 4, 20, 8, 0, 0), 
    fa: TeamID.Liverpool,
    liga: TeamID.Liverpool,
    topscorer: ['Mohamed Salah'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Tottenham,
      TeamID.Arsenal,
      TeamID.ManchesterUnited,
      TeamID.WestHam,
      TeamID.Wolverhampton,
      TeamID.Leicester,
      TeamID.Brighton,
      TeamID.Brentford,
      TeamID.Newcastle,
      TeamID.CrystalPalace,
      TeamID.AstonVilla,
      TeamID.Southampton,
      TeamID.Everton,
      TeamID.Burnley,
      TeamID.Leeds,
      TeamID.Watford,
      TeamID.Norwich,
    ]
  },
  {
    round: 'Runde 38',
    updated: new Date(2022, 4, 23, 9, 50, 0), 
    fa: TeamID.Liverpool,
    liga: TeamID.Liverpool,
    topscorer: ['Mohamed Salah', 'Heung-Min Son'],
    table: [
      TeamID.ManchesterCity,
      TeamID.Liverpool,
      TeamID.Chelsea,
      TeamID.Tottenham,
      TeamID.Arsenal,
      TeamID.ManchesterUnited,
      TeamID.WestHam,
      TeamID.Leicester,
      TeamID.Brighton,
      TeamID.Wolverhampton,
      TeamID.Newcastle,
      TeamID.CrystalPalace,
      TeamID.Brentford,
      TeamID.AstonVilla,
      TeamID.Southampton,
      TeamID.Everton,
      TeamID.Leeds,
      TeamID.Burnley,
      TeamID.Watford,
      TeamID.Norwich,
    ]
  },
];


