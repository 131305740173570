
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { Link } from "gatsby";
import * as React from "react"

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

interface MenuDrawerProps {
  anchor?: DrawerProps['anchor'];
  open: boolean;
  onClose: (event: any) => void;
}

export const MenuDrawer: React.FC<MenuDrawerProps> = ({anchor = 'left', open, onClose}) => {
  const classes = useStyles();

  const menu = [
    {text: 'Stillingen', page: '/', icon: (<FormatListNumberedIcon />)},
    {text: 'Udviklingen', page: '/progression', icon: (<ShowChartIcon />)},
    {text: 'Premier League', page: '/pltable', icon: (<SportsSoccerIcon />)},
    {text: 'Ligaudvikling', page: '/leaguedevelopment', icon: (<ShowChartIcon />)},
  ]
  return (
    <React.Fragment>
      <Drawer anchor={anchor} open={open} onClose={onClose}>
        <div className={classes.list} role="presentation">
          <List>
            {menu.map((item, i) => (
              <ListItem button component={Link} to={item.page} key={i}>
                <ListItemIcon> {item.icon} </ListItemIcon>  
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </React.Fragment>
  )
};


